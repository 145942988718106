@use './styles/_mixins.scss' as *;
.tabSection {
  position: relative;
  padding-bottom: rem(16);
  color: white;
  z-index: 2;

  @include breakpoint(medium) {
    display: grid;
    gap: 32px;
  }

  @include breakpoint(huge) {
    gap: 48px;
  }

  &_content {
    position: relative;
    @media (min-width: 500px) and (max-width: 767px) {
      max-width: 460px;
      margin: auto;

      video {
        width: 100%;
      }
    }
  }

  &_item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    visibility: hidden;

    @include breakpoint(medium) {
      display: flex;
      align-items: center;
      max-width: 1270px;
      margin: auto;
    }
    @include breakpoint(large) {
      padding: 0;
      padding: 0 rem(40);
    }

    &:not(.tabSection_item_active) {
      .tabSection_item_content {
        opacity: 0;
      }
      .tabSection_item_image {
        opacity: 0;
        transform: scale(0.95);
      }
    }

    &_active {
      z-index: 1;
      position: relative;
      visibility: visible;
    }

    &_content {
      transition: opacity 0.4s ease-in-out;
      @include breakpoint(medium) {
        width: 45%;
      }
    }

    &_image {
      margin-bottom: rem(24);
      transition:
        opacity 0.4s ease-in-out,
        transform 0.4s ease-in-out;

      @include breakpoint(medium) {
        width: 55%;
        margin-bottom: 0;
        margin-right: 16px;

        &:before {
          position: absolute;
          content: '';
          left: rem(24);
          right: rem(24);
          top: rem(24);
          bottom: rem(24);
          filter: blur(50px);
          background: var(--color-primary);
          z-index: -1;
          @include breakpoint(medium) {
            filter: blur(75px);
          }
        }
      }

      @include breakpoint(large) {
        margin-right: 64px;
      }

      img,
      video {
        max-width: 100%;
        border-radius: 8px;
        box-shadow:
          0 8px 16px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
        @include breakpoint(medium) {
          height: 430px;
          object-fit: cover;
          border-radius: 16px;
        }
      }
    }
    &_title {
      font-size: rem(18);
      margin-bottom: rem(16);
      font-weight: var(--font-light);
      @include breakpoint(medium) {
        margin-bottom: rem(32);
        font-size: rem(22);
      }
    }

    &_link {
      display: flex;
      align-items: center;
      font-size: rem(13);
      color: var(--color-gray3);
      font-weight: var(--font-medium);
      white-space: pre-wrap;

      // @supports not (aspect-ratio: 16 / 9) {
      //   white-space: unset;
      // }

      @include breakpoint(medium) {
        font-size: rem(16);
      }

      p {
        display: flex;
        flex-flow: column;
      }

      b {
        color: var(--color-white);
        font-weight: var(--font-medium);
      }

      svg {
        transition: margin 0.2s ease-in-out;
        margin-left: 20px;
        path {
          stroke: var(--color-primary);
        }
      }
      &:hover {
        svg {
          margin-left: 30px;
        }
      }
    }
  }
}
